<template>
  <div class="info">
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">基本信息</span>
        </template>
      </van-cell>
      <van-field readonly v-model="dataForm.subareaName" label="所属小区" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.fullName" label="详细位置" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.areas" label="房屋面积 m²" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.floor" label="所在楼层" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.doorModelStr" label="户型" placeholder="未知" input-align="right"/>
    </van-cell-group>

    <van-cell-group class="info-second">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">附属信息</span>
          <div style="color:#387FF5;float:right" @click="infoVisible=!infoVisible"><van-icon :name="infoVisible?'arrow-up':'arrow-down'"></van-icon></div>
        </template>
      </van-cell>
      <div v-show="infoVisible">
        <van-field readonly v-model="dataForm.useTypeStr" label="房屋属性" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.houseCard" label="不动产权证号" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.collectiveOwnership" label="共有情况" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.rentalRoomNum" label="出租间数" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.residentsLimit" label="限住人数" placeholder="未知" input-align="right"/>
        <van-field readonly :value="dataForm.manyHomeowners?'是':'否'" label="是否分户" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.code" label="统一地址编码" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.remark" placeholder="备注" type="textarea" autosize input-align="left" class="left"/>
        <van-row class="uploadBox">
          <van-col :span="24">
            <van-uploader
              v-model="urls"
              :readonly="true"
              :deletable="false"
              :show-upload="false"
            />
          </van-col>
        </van-row>
      </div>

    </van-cell-group>

    <!-- 自定义字段 -->
    <field-view ref="fieldView" :disabled="true"/>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {getImageStream} from '@/utils/index'
import {mapMutations, mapState} from 'vuex'
import fieldView from '../../components/field-view/field-view.vue';
export default {
  components: {
    topBar,
    fieldView
  },
  data() {
    return {
      urls: [],
      completeiInspect: true,
      infoVisible: true,
      dataForm: {},
      problemList: [],
      repairList: [],
      visitList: [],
      carList: [],
      disabled: false
    }
  },
  computed: {...mapState(['houseId'])},
  methods: {
    ...mapMutations(['setProblemData', 'setRepairData', 'setVisitId', 'setVisitData']),
    init() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm = data.buildingHouse

          let urls = [];
          //获取图片流
          if (data.buildingHouse.imgUrl) {
            let imgUrls = data.buildingHouse.imgUrl.split(",");

            for (let i in imgUrls) {
              let u;
              let file = imgUrls[i];
              u = getImageStream(imgUrls[i]);
              let url = {
                uid: urls.length,
                status: "done", //uploading上传中，done上传完成，error上传失败
                url: u,
                fileType: "image/png",
                realUrl: file.replace(/\\/g, "/"),
              };
              urls.push(url);
            }
          }
          this.urls = urls;
          // 自定义字段
          this.$nextTick(() => {
            this.$refs.fieldView.init(this.houseId, 2, data.buildingHouse.community)
          })
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 报修列表
    getRepairList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/repair/info/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          data.page.list.forEach(item => {
            if (item.fileDisplayVOs && item.fileDisplayVOs.length != 0) {
              let file = item.fileDisplayVOs[0]
              let url = file.relativeUrl
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.repairList = data.page.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },    // 报修列表
    getCarList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/infoCarInformation'),
        method: 'post',
        params: this.$http.adornParams({
          houseId: this.houseId,
          orgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.carList = data.data || []
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 问题列表
    getProblemList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          data.list.list.forEach(item => {
            if (item.problemFiles && item.problemFiles.length != 0) {
              let file = item.problemFiles[0]
              let url = file.filePath
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.problemList = data.list.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 走访列表
    getVisitList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/visit/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.visitList = data.page.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 跳转问题详情
    goProblemInfo(id, status) {
      let data = {'id': id, 'status': status}
      this.$store.commit('setProblemData', data)
      this.$router.push('/problem-info')
    },
    goProblem: function () {
      this.$router.push({path: '/problem', query: {houseId: this.houseId + ''}})
    },
    // 跳转报修详情
    goRepairInfo (id, status) {
      let data = {'id':id,'status':status}
      this.$store.commit('setRepairData', data)
      this.$router.push('/repair-info')
    },
    goRepair() {
      this.$router.push({path: '/repair', query: {houseId: this.houseId + ''}})
    },
    goVisitInfo(id) {
      this.$store.commit('setVisitId', id)
      this.$router.push('/visit-add')
    },
    goVisit(id) {
      this.$router.push({path: '/visit', query: {houseId: this.houseId + '', subarea: this.dataForm.subarea + '',
        buildingId: this.dataForm.buildingId + '', unitId: this.dataForm.unitId + '', buildingStr: this.dataForm.subareaName + '-' + this.dataForm.fullName}})
    },
    goCar(){
      this.$router.push({path: '/car', query: null})
    },
    goCarInfo(id,subarea){
      this.$router.push({path: '/car-add', query: {id:id, subarea: subarea}})
    }
  },
  created() {
    this.init()
    // this.getRepairList()
    // this.getProblemList()
    // this.getVisitList()
    // this.getCarList()
  }
}
</script>
<style scoped>
.list-info {
  padding-bottom: 20px !important;
}
.list-item {
  border: 1px solid #eee;
}
.list-item:nth-child(2) {
  margin-top: 20px !important;
}
.left >>> .van-field__control {
  text-align: left !important;
}
</style>
<style lang="scss" scoped>
.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
.editor-btn {
  float: right;
  border-radius: 10px;
  width: 80px;
}
</style>
